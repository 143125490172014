.marketing {
  margin-top: 25px;
}
.img-carusel {
  width: 100%;
  height: 400px;
  opacity: 0.6;
}
.col-lg-4 img {
  display: block;
  margin: auto;
}
.foryou1 p:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f058";
  color: #08b301;
  margin-right: 15px;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}

.jumbotron {
  height: 400px;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
}
.btn:focus {
  outline: none;
}
