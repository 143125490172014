.signup-form {
  /* width: 300px; */
  margin: 0 auto;
  margin-top: 50px;
  padding: 30px 0;
  font-size: 15px;
}
/* .containerCustom {
  background-color: #b6ceda;
} */
.signup-form form {
  color: #999;
  border: 1px solid #9c9c9c;
  border-radius: 3px;
  margin-bottom: 15px;
  background: #f2f3f7;
  box-shadow: 2px 2px 2px rgb(0 0 0 / 30%);
  padding: 30px;
}
.signup-form .hint-text {
  color: #999;
  margin-bottom: 30px;
  text-align: center;
}
.signup-form h2 {
  color: #636363;
  margin: 0 0 15px;
  position: relative;
  text-align: center;
}
.signup-form h2:before {
  left: 0;
}
.signup-form h2:after {
  right: 0;
}
.signup-form h2:before,
.signup-form h2:after {
  content: "";
  height: 2px;
  width: 20%;
  background: #d4d4d4;
  position: absolute;
  top: 50%;
  z-index: 2;
}
.alert-light {
  text-align: center;
}
#login {
  color: #7dbcff;
}
