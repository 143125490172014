.img-carusel {
  width: 100%;
  height: 400px;
  opacity: 0.6;
}
.col-lg-4 img {
  display: block;
  margin: auto;
}
.marketing {
  margin-top: 25px;
}

.carousel-caption h1 {
  font-size: 2em;
  color: #3d3063;
  text-shadow: 1px 1px rgb(0 0 0 / 30%);
}
.carousel-caption p {
  color: rgb(49, 49, 49);
  text-shadow: 1px 1px rgb(0 0 0 / 30%);
}
.carousel-indicators li {
  background-color: #b3c3c9;
}
